import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Polymer Render`}</h1>
    <p>{`Polymer render is a cement based system with specially selected polymers added to the mix. These polymers make the finished coat strong yet flexible, allowing them to be used on a number of substrates.`}</p>
    <p>{`Silicone water repellents are also an integral part of the premixed polymer/cement based render system. This silicone technology imparts a high degree of water repellence to the render surface whilst allowing water vapour to pass through the render, letting the substrate breathe.`}</p>
    <p>{`The use of nylon reinforced base coats provides a high strength base for the finish coat. Polymer render is available as monocouche (one coat) render, eliminating the need for a base coat. The monocouche renders are usually only applied to new build lightweight blocks or breeze block. Expansion joints often need to be used to ensure the render does not crack. Colour matched PVC corner beads and stop beads can be used to enhance the appearance of the render or provide a contrast.`}</p>
    <p>{`Polymer renders come in a wide range of colours and can be finished in different textures, from a flat sponged float finish or a rougher scraped finish as well as `}<a parentName="p" {...{
        "href": "/render-types/pebble-dash/",
        "title": "Pebble Dash"
      }}>{`Pebble Dash`}</a>{`.`}</p>
    <p>{`They are usually premixed and only require the addition of clean water to produce the final product. This ensures the quality of the render remains consistent and ensures a superior finish.`}</p>
    <p>{`Suitable substrates: light weight thermalite block, breeze block, modern brick, Steel framed buildings, insulation boards, phonelic insulation board, polystyrene insulation sheets and metal lath. Other substrate may also be suitable.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      